import { SetDeliveryTimeAction } from "@js/actions/filterActions";
import { SetMethodologyAction } from "@js/actions/filterActions";
import { SetOrderByAction } from "@js/actions/filterActions";
import { SetPriceMaxAction } from "@js/actions/filterActions";
import { SetPriceMinAction } from "@js/actions/filterActions";
import { SetPriceTierMaxAction } from "@js/actions/filterActions";
import { SetPriceTierMinAction } from "@js/actions/filterActions";
import { SetReviewAverageAction } from "@js/actions/filterActions";

import { SET_DELIVERY_TIME } from "@js/actionTypes/filterActionTypes";
import { SET_METHODOLOGY } from "@js/actionTypes/filterActionTypes";
import { SET_ORDER_BY } from "@js/actionTypes/filterActionTypes";
import { SET_PRICE_MAX } from "@js/actionTypes/filterActionTypes";
import { SET_PRICE_MIN } from "@js/actionTypes/filterActionTypes";
import { SET_PRICE_TIER_MAX } from "@js/actionTypes/filterActionTypes";
import { SET_PRICE_TIER_MIN } from "@js/actionTypes/filterActionTypes";
import { SET_REVIEW_AVERAGE } from "@js/actionTypes/filterActionTypes";

/**
 * @const setDeliveryTime
 */
export const setDeliveryTime = (deliveryTime: string): SetDeliveryTimeAction => ({
    payload: {
        deliveryTime: deliveryTime
    },
    type: SET_DELIVERY_TIME
});

/**
 * @const setMethodology
 */
export const setMethodology = (methodology: string): SetMethodologyAction => ({
    payload: {
        methodology: methodology
    },
    type: SET_METHODOLOGY
});

/**
 * @const setOrderBy
 */
export const setOrderBy = (orderBy: string): SetOrderByAction => ({
    payload: {
        orderBy: orderBy
    },
    type: SET_ORDER_BY
});

/**
 * @const setPriceMax
 */
export const setPriceMax = (priceMax: number): SetPriceMaxAction => ({
    payload: {
        priceMax: priceMax
    },
    type: SET_PRICE_MAX
});

/**
 * @const setPriceMin
 */
export const setPriceMin = (priceMin: number): SetPriceMinAction => ({
    payload: {
        priceMin: priceMin
    },
    type: SET_PRICE_MIN
});

/**
 * @const setPriceTierMax
 */
export const setPriceTierMax = (priceTierMax: number): SetPriceTierMaxAction => ({
    payload: {
        priceTierMax: priceTierMax
    },
    type: SET_PRICE_TIER_MAX
});

/**
 * @const setPriceTierMin
 */
export const setPriceTierMin = (priceTierMin: number): SetPriceTierMinAction => ({
    payload: {
        priceTierMin: priceTierMin
    },
    type: SET_PRICE_TIER_MIN
});

/**
 * @const setReviewAverage
 */
export const setReviewAverage = (reviewAverage: string): SetReviewAverageAction => ({
    payload: {
        reviewAverage: reviewAverage
    },
    type: SET_REVIEW_AVERAGE
});
