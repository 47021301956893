import React from "react";

import { SVGProps } from "react";

/**
 * @type GripLinesVerticalIconProps
 */
export type GripLinesVerticalIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const GripLinesVerticalIcon
 */
const GripLinesVerticalIcon = (props: GripLinesVerticalIconProps) => {
    return (
        <svg { ...props } aria-hidden="true" data-prefix="fas" role="img" viewBox="0 0 256 512" xmlns="http://www.w3.org/2000/svg">
            <path d="M96 496V16c0-8.8-7.2-16-16-16H48c-8.8 0-16 7.2-16 16v480c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16zm128 0V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v480c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16z" fill="currentColor" />
        </svg>
    );
};

export default GripLinesVerticalIcon;
