import React, {DetailedHTMLProps, HTMLAttributes} from "react";
/**
 * @type PropositionBannerProps
 */
export type PropositionBannerProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

/**
 * @const ArrowLeftIcon
 */
const PropositionBanner = (props: PropositionBannerProps) => {
    const {
        className,
        banner,
        ...restProps
    } = props;

    return (
        <img src={ banner } { ...restProps } className={ className } alt="banner" />
    );
};

export default PropositionBanner;
