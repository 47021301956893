import ReactSlider from "react-slider";

import React from "react";

import { ReactSliderProps } from "react-slider";

import clsx from "clsx";

import GripLinesVerticalIcon from "@js/component/icon/GripLinesVerticalIcon";

import * as classNames from "@css/component/Slider.module.scss";

/**
 * @type SliderProps
 */
export type SliderProps = ReactSliderProps;

/**
 * @const Slider
 */
const Slider = (props: SliderProps) => {
    const {
        className,
        ...restProps
    } = props;

    return (
        <ReactSlider
            { ...restProps }
            className={ clsx(classNames.slider, className) }
            renderThumb={ (props, state) => (
                <div { ...props }>
                    <GripLinesVerticalIcon className={ classNames.icon } />
                    <div className={ classNames.tooltip }>{ state.valueNow }</div>
                </div>
            ) }
            thumbClassName={ classNames.thumb }
            trackClassName={ classNames.track }
        />
    );
};

export default Slider;
